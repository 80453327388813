<template>
  <b-modal
    v-model="showModal"
    header-bg-variant="transparent"
    header-text-variant="primary"
    centered
    size="xmd"
    hide-footer
    @hidden="closeModal"
  >
    <template #modal-title>
      <h5
        class="text-uppercase font-weight-bolder d-flex justify-content-center align-items-center"
        :class="textColorStatus[params.statusService]"
      >
        <div
          class="mr-1 rounded-lg p-50 d-flex justify-content-center align-items-center"
          style="background-color: #a7c5ff"
        >
          <img
            v-if="params.img"
            :src="`${assetsBaseUrl}/${params.img}`"
            width="20"
            height="20"
          />
          <feather-icon v-else icon="CreditCardIcon" size="18" />
        </div>
        {{
          `${nameStatus[params.statusService]} ${params.type} of ${
            params.nameDepartment
          }`
        }}
      </h5>
    </template>
    <b-container fluid class="p-0">
      <filter-slot
        class="border-table-radius"
        :filter="[]"
        :filter-principal="{}"
        :no-visible-principal-filter="true"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['tableDetails'].refresh()"
      >
        <b-table
          slot="table"
          ref="tableDetails"
          class="shadow-none custom-table"
          :class="classTable[params.statusService]"
          :items="myProvider"
          :fields="visibleFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :head-row-variant="'grid-custom-tickets-header'"
          :busy.sync="isBusy"
          sticky-header="50vh"
          show-empty
          responsive
          no-provider-filtering
          :foot-clone="false"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="{ item }">
            <div
              class="d-flex flex-column justify-content-start"
              style="200px;"
            >
              <strong>{{ item.name_client }}</strong>
              <span>{{ item.account }}</span>
              <b-badge
                style="width: 80px"
                :variant="bgBadge[item.client_type_id - 1]"
                v-if="item.program_id == 3"
                >{{ item.client_type_id == 1 ? "REGULAR" : "DIGITAL" }}</b-badge
              >
            </div>
          </template>
          <template #cell(type_service)="{ item }">
            <span>{{ item.type_service.toUpperCase() }}</span>
          </template>
          <template #cell(recommended_by)="{ item }">
            <div class="d-flex flex-column">
              <span>{{ item.recommended_by }}</span>
              <span>{{ item.recommended_at | myGlobalDay }}</span>
            </div>
          </template>
          <template #cell(proccess_by)="{ item }">
            <div class="d-flex flex-column">
              <span>{{ item.proccess_by }}</span>
              <span>{{ item.proccess_at | myGlobalDay }}</span>
            </div>
          </template>
          <template #cell(executed_by)="{ item }">
            <div class="d-flex flex-column">
              <span>{{ item.executed_by }}</span>
              <span>{{ item.executed_at | myGlobalDay }}</span>
            </div>
          </template>
          <template #cell(paid_amount)="{ item }">
            <strong :class="textColorStatus[params.statusService]"
              >$ {{ item.paid_amount | currency }}
            </strong>
          </template>
          <template #custom-foot v-if="[2, 3].includes(params.statusService)">
            <b-tr class="bg-primary fixed-footer">
              <b-th :colspan="indexAmount" />
              <b-th class="py-1">
                <b-badge
                  style="font-size: 13px"
                  variant="info"
                  class="rounded py-50"
                >
                  Total :<b style="padding: 2px 4px" class="text-white brounded"
                    >$ {{ subtotalAmount | currency }}</b
                  >
                </b-badge>
              </b-th>
            </b-tr>
          </template>
        </b-table>
      </filter-slot>
    </b-container>
  </b-modal>
</template>
<script>
import DashboardServices from "@/views/specialists/sub-modules/financial-approval/views/dashboard/services/dashboard.services";
import Fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/otherServices/data/details.fields";
export default {
  props: {
    params: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  async created() {
    console.log(this.params)
    this.filterColumn("recommended_by", !this.isPotentialCard);
    this.filterColumn("proccess_by", !this.isPotentialCard && [2,3].includes(this.params.statusService));
    this.filterColumn("executed_by", this.params.statusService == 3 || (this.params.type == 'CREDIT CARDS' && [3,4].includes(this.params.statusService)));
    this.filterColumn("paid_amount", [2,3].includes(this.params.statusService));
  },
  async mounted() {
    this.showModal = true;
  },
  computed: {
    isPotentialCard() {
      return this.params.type == "POTENTIAL";
    },
    isPaidBar() {
      return this.params.statusService == 3;
    },
    indexAmount() {
      return this.fields
        .filter((item) => item.visible === true)
        .findIndex((item) => item.key == "paid_amount");
    },
    visibleFields() {
      return this.fields.filter((item) => item.visible === true);
    },
  },
  data() {
    return {
      isBusy: false,
      showModal: false,
      assetsBaseUrl: `${process.env.BASE_URL}assets/images/icons/`,
      paginate: {
        perPage: 100,
        currentPage: 1,
      },
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      fields: Fields,
      bgBadge: ["light-info", "light-warning"],
      subtotalAmount: 0,
      nameStatus: {
        1: "pending",
        2: "accepted",
        3: "paid",
        4: "no paid",
        5: "rejected",
      },
      textColorStatus: {
        1: "text-warning",
        2: "text-primary",
        3: "text-success",
        4: "text-secondary",
        5: "text-danger",
      },
      classTable: {
        1: "head-warning",
        2: "head-primary",
        3: "head-success",
        4: "head-secondary",
        5: "head-danger",
      },
    };
  },
  methods: {
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async myProvider() {
      try {
        const params = {
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          departmentId: this.params.departmentId,
          specialistId: this.params.specialistId,
          typeService: this.params.typeId,
          year: this.params.year,
          month: this.params.month,
          statusService: this.params.statusService,
        };
        const { data } = await DashboardServices.getDetailsIndicator(params);
        const myData = data.data;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;
        this.totalRows = myData.length > 0 ? myData[0].cc : 0;
        this.paginate.currentPage = data.current_page || 0;
        this.paginate.perPage = data.per_page || 0;

        this.subtotalAmount = myData.reduce((sum, item) => {
          sum += parseFloat(item.paid_amount);
          return sum;
        }, 0);

        return myData;
      } catch (error) {
        this.showErrorSwal();
        throw error;
      }
    },
  },
};
</script>
<style scoped>
.fixed-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
}
.custom-table >>> .head-pending thead tr th {
  background-color: #ffa01b !important;
}
.custom-table >>> .head-secondary thead tr th {
  background-color: #83868c !important;
}
.custom-table >>> .head-success thead tr th {
  background-color: #6df9bf !important;
}
.custom-table >>> .head-danger thead tr th {
  background-color: #ff001e !important;
}
</style>
