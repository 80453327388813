export default [
    {
        key: 'client_name',
        label: 'client',
        visible: true,
    },
    {
        key: 'type_service',
        name: 'service',
        visible: false,
    },
    {
        key: 'name_charge',
        label: 'Charge',
        visible: true,
        thClass: 'text-center',
        tdClass: 'text-center',
    },
    {
        key: 'recommended_by',
        label: 'recommended by',
        visible: true,
    },
    {
        key: 'executed_by',
        label: 'executed by',
        visible: true,
    },
    {
        key: 'proccess_by',
        label: 'processed by',
        visible: true,
    },
    {
        key: 'paid_amount',
        label: 'Paid amount',
        thClass: "text-center",
        tdClass: "text-center pr-2",
        visible: true,
    },
];