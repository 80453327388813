<template>
  <b-card class="p-50 rounded-lg" style="height: 100% !important">
    <template #header>
      <div class="d-flex justify-content-between align-items-start w-100">
        <h4
          class="font-weight-bolder text-uppercase mb-0 d-flex justify-content-start align-items-center"
        >
          <div
            class="mr-1 rounded-lg p-50 d-flex justify-content-center align-items-center"
            style="background-color: #a7c5ff"
          >
            <img src="@/assets/images/icons/agent.svg" width="30" height="30" />
          </div>
          AGENTS PRODUCTIVITY
        </h4>
        <div class="d-flex justify-content-center align-items-center">
          <b-button-group>
            <b-button
              v-b-tooltip.hover.v-primary="'ACCEPTED SERVICES'"
              v-b-tooltip.hover.top
              size="sm"
              :variant="
                typeView === 'RECOMMENDED' ? 'primary' : 'outline-primary'
              "
              class="text-uppercase"
              @click="typeView = 'RECOMMENDED'"
            >
              Recommended
            </b-button>
            <b-button
              v-b-tooltip.hover.v-primary="'PAID SERVICES'"
              v-b-tooltip.hover.top
              size="sm"
              :variant="typeView === 'EXECUTED' ? 'primary' : 'outline-primary'"
              class="text-uppercase"
              @click="typeView = 'EXECUTED'"
            >
              Executed
            </b-button>
          </b-button-group>
          <b-button-group class="ml-1" v-if="typeView == 'EXECUTED'">
            <b-button
              size="sm"
              :variant="showAmount ? 'primary' : 'outline-primary'"
              class="text-uppercase"
              v-b-tooltip.hover.v-primary="'AMOUNT'"
              @click="showAmount = true"
            >
              <feather-icon icon="DollarSignIcon" size="12" />
            </b-button>
            <b-button
              size="sm"
              :variant="!showAmount ? 'primary' : 'outline-primary'"
              class="text-uppercase"
              v-b-tooltip.hover.v-primary="'QUANTITY'"
              @click="showAmount = false"
            >
              <feather-icon icon="CreditCardIcon" size="12" />
            </b-button>
          </b-button-group>
        </div>
      </div>
    </template>
    <b-card-body class="p-0">
      <b-overlay
        :show="isLoading"
        :variant="isDarkSkin ? 'dark' : 'light'"
        class="rounded-lg"
      >
        <vue-apex-charts
          v-if="series.length"
          :key="keyChart"
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></vue-apex-charts>
        <div
          class="d-flex justify-content-center align-items-center w-100 position-relative"
          v-else
        >
          <img
            src="@/assets/images/noData/no-data-productivity.svg"
            class="custom-img"
          />
          <span class="mt-3 text-uppercase custom-span"
            >No service has been {{ typeView }}</span
          >
        </div>
      </b-overlay>
    </b-card-body>
    <b-row class="my-1">
      <b-col cols="12" class="text-center d-flex justify-content-around">
        <div
          class="d-flex justify-content-center align-items-center"
          v-for="(item, index) in legendsName"
          :key="index"
        >
          <span
            class="rounded-sm custom-item mr-1"
            :style="`background-color: ${item.color}`"
          ></span>
          <strong class="text-capitalize"
            >{{ item.name }}
            <template v-if="showAmount"
              >($ {{ item.total | currency }})</template
            >
            <template v-else>({{ item.total }})</template>
          </strong>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
  <script>
import VueApexCharts from "vue-apexcharts";
import DashboardServices from "@/views/specialists/sub-modules/financial-approval/views/dashboard/services/dashboard.services";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    month: {
      type: Number,
      require: true,
      default: () => 0,
    },
    year: {
      type: Number,
      require: true,
      default: () => 0,
    },
    departmentId: {
      type: Number,
      require: true,
      default: () => 0,
    },
  },
  mounted() {
    const color = this.isDarkSkin ? "#ffffff" : "#2E4053";
    this.chartOptions.yaxis.labels.style.colors = color;
    this.chartOptions.xaxis.labels.style.colors = color;
    this.chartOptions.legend.labels.colors = color;
    this.getDataAgentProductivity();
  },
  computed: {
    legendsName() {
      return this.series.map((item, index) => {
        const total = item.data.reduce((sum, val) => {
          sum += val;
          return sum;
        }, 0);
        return {
          name: item.name,
          total: this.showAmount ? total.toFixed(2) : total,
          color: this.chartOptions.fill.colors[index],
        };
      });
    },
  },
  data() {
    return {
      isLoading: false,
      keyChart: 0,
      typeView: "RECOMMENDED",
      series: [], // array of objects example: [{name: 'required', data: [1,2,40,5,3]},{name: 'required 2', data: [10,2,40,5,3]}]
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          events: {
            dataPointMouseEnter: (event, chartContext, config) => {
              const tooltip = chartContext.el.querySelector(
                ".apexcharts-tooltip"
              );
              const tooltipTitle = chartContext.el.querySelector(
                ".apexcharts-tooltip-title"
              );
              const color = this.isDarkSkin ? "#F7F7F7" : "#fffff";
              const backgroundColor = this.isDarkSkin ? "#3A3A3A" : "#ffffff";
              if (tooltip) {
                tooltip.style.backgroundColor = backgroundColor;
                tooltip.style.color = color;
                tooltip.style.border = "0px solid #000000";
              }
              if (tooltipTitle) {
                tooltipTitle.style.backgroundColor = this.isDarkSkin
                  ? "transparent"
                  : "#e4eaec";
                tooltipTitle.style.color = color;
                tooltipTitle.style.borderBottom = "0px solid #000000";
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          colors: ["#fff"],
        },
        title: {
          text: undefined, // title of chart
        },
        xaxis: {
          categories: [], // array of values example: [1,2,3,40,5]
          labels: {
            formatter: function (val) {
              return parseInt(val, 10);
            },
            show: true,
            style: {
              colors: "#ffffff",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return val;
            },
            show: true,
            style: {
              colors: "#ffffff",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
          colors: ["#01E363", "#3F7AFA", "#AA00F1", "#3FC8FF"], //if you wan change colors,
        },
        legend: {
          show: false,
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 80,
          offsetY: 10,
          labels: {
            color: undefined,
            useSeriesColors: false,
          },
        },
        colors: ["#01E363", "#3F7AFA", "#AA00F1", "#3FC8FF"], //if you wan change colors,
      },
      showAmount: false,
      dataProductivity: [],
    };
  },
  methods: {
    async getDataAgentProductivity() {
      try {
        this.isLoading = true;
        const params = {
          department_id: this.departmentId,
          month: this.month,
          year: this.year,
          type: this.typeView === "RECOMMENDED" ? 1 : 0,
        };
        const { data } = await DashboardServices.getAgentProductivity(params);
        this.dataProductivity = data;
        this.setDataGraphics(this.dataProductivity);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.showErrorSwal();
        console.log("error in getDataAgentProductivity: ", error);
      }
    },
    setDataGraphics(data) {
      if (data.length === 0) {
        this.series = [];
        this.chartOptions.xaxis.categories = [];
        this.keyChart += 1;
        return;
      }

      // create the "arrays" to 'each services' (the services are the 'keys' of object)
      const typeServices = Object.keys(data[0]).filter(
        (item) => item !== "user_name"
      );
      const dataServices = typeServices.reduce((array, item) => {
        array[item.toUpperCase()] = [];
        return array;
      }, {});

      // set values to each array of service
      data.forEach((item) => {
        typeServices.forEach((key) => {
          const values = this.showAmount
            ? item[key][0].amount
            : item[key][0].cant;
          dataServices[key.toUpperCase()].push(values);
        });
      });

      // set values to chart
      const series = typeServices.map((type) => {
        return {
          name: type.replace("_", " ").toUpperCase(),
          data: dataServices[type.toUpperCase()],
        };
      });
      this.series = series;
      this.chartOptions.xaxis.categories = data.map((item) => item.user_name);
      this.keyChart += 1;
    },
  },
  watch: {
    isDarkSkin(newVal) {
      let color = "#ffffff";
      if (!newVal) {
        color = "#2E4053";
      }
      this.chartOptions.yaxis.labels.style.colors = color;
      this.chartOptions.xaxis.labels.style.colors = color;
      this.chartOptions.legend.labels.colors = color;
      this.keyChart += 1;
    },
    async typeView() {
      this.showAmount = this.typeView === 'EXECUTED';
      await this.getDataAgentProductivity();
    },
    showAmount() {
      this.isLoading = true;
      this.setDataGraphics(this.dataProductivity);
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.custom-item {
  width: 15px;
  height: 15px;
  display: block;
}
.custom-img {
  /* padding: 20px 20px; */
  width: 300px;
  min-width: 100px;
  height: 400px;
  opacity: 0.1;
}
.custom-span {
  position: absolute;
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
  z-index: 2;
  color: #979595;
}
.custom-checkbox-chart-dash {
  /* transform: translateY(-5px); */
}
.custom-checkbox-chart-dash >>> .custom-control-label {
  padding-left: 0px !important;
}
.text-w {
  color: #fff !important;
}
.custom-checkbox-chart-dash-dark
  >>> .custom-control-input:not(:checked)
  ~ .custom-control-label:before {
  background-color: #0090e7 !important;
  border: 1px solid #ffffff;
}
.custom-checkbox-chart-dash-light
  >>> .custom-control-input:not(:checked)
  ~ .custom-control-label:before {
  background-color: #0090e7 !important;
  border: 1px solid #ffffff;
}
.custom-checkbox-chart-dash >>> label {
  color: #fff !important;
  font-size: 1.5rem !important;
  font-weight: bold;
  margin: 0 !important;
  padding: 5px;
}
.custom-checkbox-chart-dash-dark
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #00d25b !important;
  border: 1px solid #ffffff;
}
.custom-checkbox-chart-dash-light
  >>> .custom-control-input:checked
  ~ .custom-control-label:before {
  background-color: #00d25b !important;
}
.custom-badge-ckecked {
  background: #00d25b;
  color: #fff;
  font-size: 12px;
  transform: translateY(-5px);
}
.custom-badge-not-ckecked {
  background: #0090e7 !important;
  color: #fff;
  font-size: 12px;
  transform: translateY(-5px);
}
</style>