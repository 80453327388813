<template>
  <div class="px-2 mt-1">
    <header-slot> </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'dashboardNCR' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        NCR
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'dashboardServices' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        SERVICES
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'dashboardAnalysisCr' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="isActive"
      >
        ANALYSIS CR
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'dashboardAutomaticPayments' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        AUTOMATIC PAYMENTS
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'dashboardExpenses' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        EXPENSES
      </b-nav-item>
    </b-nav>
    <router-view
      ref="viewDashboard"
      :route="$route.name"
      class="border-top-primary border-3 px-0 py-3 px-md-1"
      :class="isDarkSkin ? 'bg-transparent' : 'bg-'"
    ></router-view>
  </div>
</template>
<script>
export default {
  computed: {
    isActive() {
      const { dashboardLead, dashboardSettlement, dashboardRemoved } =
        this.$route.meta;
      return [dashboardLead, dashboardSettlement, dashboardRemoved].includes(
        this.$route.name
      );
    },
  },
};
</script>
