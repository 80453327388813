<template>
  <div style="position: relative !important">
    <!--filters-->
    <b-row class="mb-1 mx-15 rounded-lg">
      <b-col cols="12">
        <b-row :class="isDarkSkin ? 'bg-dark' : 'bg-white'" class="rounded-lg">
          <b-col cols="12" sm="6" md="3" xl="2" class="mt-50">
            <div class="rounded-lg">
              <b-form-group class="font-weight-bolder">
                <template #label>
                  <feather-icon icon="GridIcon" class="mr-50" />
                  Departament
                </template>
                <v-select
                  v-model="departmentSelected"
                  :clearable="true"
                  label="name"
                  :options="optionsDepartments"
                  placeholder="--Select--"
                  :reduce="(option) => option.id"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" sm="6" md="3" xl="2" class="mt-50">
            <div class="rounded-lg">
              <b-form-group class="font-weight-bolder">
                <template #label>
                  <feather-icon icon="CalendarIcon" />
                  Year
                </template>
                <v-select
                  v-model="yearSelected"
                  :clearable="false"
                  label="value"
                  :options="optionsYears"
                  placeholder="--Select--"
                  :reduce="(option) => option.id"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" sm="6" md="3" class="mt-50">
            <div class="rounded-lg">
              <b-form-group class="font-weight-bolder">
                <template #label>
                  <feather-icon icon="CalendarIcon" class="mr-50" />
                  Month
                </template>
                <v-select
                  v-model="monthSelected"
                  :clearable="false"
                  label="value"
                  :options="optionsMonths"
                  placeholder="--Select--"
                  :disabled="!yearSelected"
                  :reduce="(option) => option.id"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" sm="6" md="3" class="mt-50">
            <div class="rounded-lg">
              <b-form-group class="font-weight-bolder">
                <template #label>
                  <feather-icon icon="GridIcon" class="mr-50" />
                  Specialists
                </template>
                <v-select
                  v-model="specialistSelected"
                  :clearable="true"
                  label="name"
                  :options="optionsSpecialists"
                  placeholder="--Select--"
                  :reduce="(option) => option.id"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col
            cols="12"
            xl="2"
            class="mt-50 d-flex justify-content-center align-items-center text-center mb-2 mb-xl-0"
          >
            <b-button
              variant="primary"
              @click="getDataIndicators(true)"
              class="w-100 mt-50"
            >
              <feather-icon icon="RefreshCcwIcon" class="mr-1" /> REFRESH
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <div
        class="d-flex flex-lg-column justify-content-center align-items-center text-center p-1 rounded-left shadow-lg success-flag"
        :class="isDarkSkin ? 'bg-success-dark' : 'bg-success'"
        @click="getGlobalDetails"
      >
        <span
          class="d-flex mx-1 mx-lg-0 justify-content-center align-items-center text-white"
          style="font-size: 18px"
          >Total</span
        >
        <feather-icon
          v-if="isLoading"
          icon="LoaderIcon"
          size="20"
          class="spinner"
        />
        <h3 v-else class="text-white font-weight-bolder mt-50 mt-lg-0">
          $ {{ totalGlobal | currency }}
        </h3>
      </div>
    </b-row>
    <!--data-->
    <b-row class="p-0">
      <b-col cols="12" xl="6">
        <b-overlay
          :show="isLoading"
          :variant="isDarkSkin ? 'dark' : 'light'"
          class="rounded-lg"
        >
          <score-card
            :key="keyDashboard"
            :type="1"
            :data="dataCreditCards"
            svg="credit-cards.svg"
            :showChart="chartCC"
            :month="monthSelected"
            :year="yearSelected"
            :specialistId="specialistSelected"
            @showChart="viewChartCC"
            @totalAmount="miTotal"
          />
        </b-overlay>
      </b-col>
      <b-col cols="12" xl="6">
        <b-overlay
          :show="isLoading"
          :variant="isDarkSkin ? 'dark' : 'light'"
          class="rounded-lg"
        >
          <score-card
            :key="keyDashboard"
            :type="2"
            :data="dataAppointments"
            svg="appointment.svg"
            :showChart="chartAp"
            :showNoPaid="false"
            :month="monthSelected"
            :year="yearSelected"
            :specialistId="specialistSelected"
            @showChart="viewChartAp"
            @totalAmount="miTotal"
          />
        </b-overlay>
      </b-col>
      <b-col cols="12" xl="6">
        <b-overlay
          :show="isLoading"
          :variant="isDarkSkin ? 'dark' : 'light'"
          class="rounded-lg"
        >
          <score-card
            :key="keyDashboard"
            :type="3"
            :data="dataOthersServices"
            svg="other-services.svg"
            :showChart="chartOS"
            :showNoPaid="false"
            :month="monthSelected"
            :year="yearSelected"
            :specialistId="specialistSelected"
            @showChart="viewChartOS"
            @totalAmount="miTotal"
          />
        </b-overlay>
      </b-col>
      <b-col cols="12" xl="6">
        <b-overlay
          :show="isLoading"
          :variant="isDarkSkin ? 'dark' : 'light'"
          class="rounded-lg"
        >
          <score-card
            :key="keyDashboard"
            :type="4"
            :data="dataPotentials"
            svg="potential.svg"
            :showChart="chartP"
            :month="monthSelected"
            :year="yearSelected"
            :specialistId="specialistSelected"
            @showChart="viewChartP"
            @totalAmount="miTotal"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="6">
        <b-overlay
          :show="isLoading"
          :variant="isDarkSkin ? 'dark' : 'light'"
          class="rounded-lg"
        >
          <productivity-agent
            :key="keyDashboard"
            :month="monthSelected"
            :year="yearSelected"
            :department-id="departmentSelected"
          />
        </b-overlay>
      </b-col>
      <b-col cols="12" xl="6">
        <b-overlay
          :show="isLoading"
          :variant="isDarkSkin ? 'dark' : 'light'"
          class="rounded-lg"
        >
          <productivity-department
            :key="keyDashboard"
            :month="monthSelected"
            :year="yearSelected"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <!--MODAL DETAILS OF BAR-->
    <modal-details
      v-if="showModalDetails"
      :params="paramsDetails"
      @closeModal="closeDetails"
    />
  </div>
</template>

<script>
import ScoreCard from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/otherServices/ScoreCard.vue";
import ProductivityDepartment from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/otherServices/ProductivityDepartment.vue";
import ProductivityAgent from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/otherServices/ProductivityAgent.vue";
import DashboardServices from "@/views/specialists/sub-modules/financial-approval/views/dashboard/services/dashboard.services.js";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import ModalDetails from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/otherServices/Modals/ModalDetails.vue"

export default {
  components: {
    ScoreCard,
    ProductivityDepartment,
    ProductivityAgent,
    ModalDetails,
  },
  props: {
    card: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      G_DEPARTMENTS: "DashboardFinancialStore/G_DEPARTMENTS",
    }),
  },
  created() {
    this.generateYears();
  },
  async mounted() {
    await this.getSpecialists();
    await this.A_DEPARTMENTS();
    this.setDataDepartments();
  },
  data() {
    return {
      optionsDepartments: [],
      departmentSelected: null,
      optionsYears: [],
      yearSelected: null,
      optionsMonths: [],
      monthSelected: parseInt(moment().format("MM"), 10),
      currentYear: parseInt(moment().format("YYYY"), 10),
      dataCreditCards: [],
      dataAppointments: [],
      dataOthersServices: [],
      dataPotentials: [],
      dataDepartmentProductivity: [],
      bgVariant: {
        accepted: "primary",
        rejected: "danger",
        paid: "success",
        "no paid": "secondary",
      },
      icon: {
        accepted: "CheckCircleIcon",
        rejected: "UserXIcon",
        paid: "CreditCardIcon",
        "no paid": "XCircleIcon",
      },
      keyDashboard: 0,
      isLoading: false,
      chartCC: true,
      chartAp: true,
      chartOS: true,
      chartP: true,
      totalGlobal: 0,
      optionsSpecialists: [],
      specialistSelected: null,
      showModalDetails: false,
      paramsDetails: {},
    };
  },
  methods: {
    ...mapActions({
      A_DEPARTMENTS: "DashboardFinancialStore/A_DEPARTMENTS",
    }),
    setDataDepartments() {
      this.optionsDepartments = this.G_DEPARTMENTS.map((item) => {
        return {
          id: item.id,
          initial: item.initial,
          name: `${item.name} (${item.initial})`,
        };
      });
    },
    generateYears() {
      this.optionsYears = [];
      for (let index = 2024; index <= this.currentYear; index++) {
        this.optionsYears.push({ id: index, value: index });
      }
      this.yearSelected = this.currentYear;
    },
    generateMonths() {
      const months =
        this.yearSelected < this.currentYear
          ? 12
          : parseInt(moment().format("MM"), 10);
      this.optionsMonths = [];
      for (let index = 1; index <= months; index++) {
        const myMonth = moment(index, "MM").format("MMMM");
        this.optionsMonths.push({ id: index, value: myMonth });
      }
      const currentMonth = parseInt(moment().format("MM"), 10);
      this.monthSelected =
        this.yearSelected < this.currentYear ||
        this.monthSelected <= currentMonth
          ? this.monthSelected
          : currentMonth;
    },
    async getDataIndicators(clearFilter = false) {
      try {
        if (clearFilter) {
          this.clearFilters();
        }

        this.isLoading = true;
        this.totalGlobal = 0;
        const params = {
          department_id: this.departmentSelected,
          year: this.yearSelected,
          month: this.monthSelected,
          specialist_id: this.specialistSelected,
        };
        const { data } = await DashboardServices.getIndicators(params);
        // data to indicators
        this.dataCreditCards =
          data.length > 0
            ? data.filter(
                (item) => item.group_name.toLowerCase() === "credit_cards"
              )
            : [];
        this.dataAppointments =
          data.length > 0
            ? data.filter(
                (item) => item.group_name.toLowerCase() === "appointments"
              )
            : [];
        this.dataOthersServices =
          data.length > 0
            ? data.filter(
                (item) => item.group_name.toLowerCase() === "others_services"
              )
            : [];
        this.dataPotentials =
          data.length > 0
            ? data.filter(
                (item) => item.group_name.toLowerCase() === "potential"
              )
            : [];

        // refresh components
        this.isLoading = false;
        this.keyDashboard++;
      } catch (error) {
        this.totalGlobal = 0;
        this.isLoading = false;
        this.showErrorSwal();
        console.log("error in getDataIndicators: ", error);
      }
    },
    viewChartCC(showChart) {
      this.chartCC = showChart;
    },
    viewChartAp(showChart) {
      this.chartAp = showChart;
    },
    viewChartOS(showChart) {
      this.chartOS = showChart;
    },
    viewChartP(showChart) {
      this.chartP = showChart;
    },
    miTotal(amount) {
      this.totalGlobal += amount;
    },
    async getSpecialists() {
      try {
        const { data } = await DashboardServices.getUserSpecialists();
        this.optionsSpecialists = data;
      } catch (error) {
        console.log("error in getUserSpecialists: ", error);
      }
    },
    clearFilters() {
      this.departmentSelected = null;
      this.specialistSelected = null;
    },
    getGlobalDetails() {
      const { id, name } = this.departmentSelected ? this.optionsDepartments.find(item => item.id == this.departmentSelected) : {id: null, name: null};
      this.paramsDetails = {
        departmentId: id,
        nameDepartment: !id ? 'ALL DEPARTMENTS': name,
        type: 'RECOMMENDED SERVICES',
        typeId: null,
        year: this.yearSelected,
        month: this.monthSelected,
        specialistId: this.specialistSelected,
        img: null,
        statusService: 3
      };
      this.showModalDetails = true;
    },
    closeDetails() {
      this.showModalDetails = false;
      this.paramsDetails = {};
    },
  },
  watch: {
    async yearSelected(newVal) {
      this.generateMonths();
      await this.getDataIndicators();
    },
    async monthSelected(newVal) {
      await this.getDataIndicators();
    },
    async departmentSelected(newVal) {
      await this.getDataIndicators();
    },
    async specialistSelected(newVal) {
      await this.getDataIndicators();
    },
  },
};
</script>

<style scoped>
.mx-15 {
  margin: 0px 0px 30px 0px !important;
}
.bg-success-dark {
  background-color: #229954;
}
.success-flag {
  position: fixed !important;
  top: 300px;
  right: 0px;
  z-index: 99;
  cursor: pointer;
  transition: 0.5s;
}
.success-flag:hover {
  transform: scale(1.1) translateX(-5px);
}
</style>