<template>
  <div>
    <b-card class="p-1 rounded-lg" style="height: 85% !important">
      <template #header>
        <div
          class="d-flex justify-content-between align-items-center w-100 flex-wrap"
        >
          <h4
            class="font-weight-bolder text-uppercase mb-0 d-flex justify-content-start align-items-center"
            :class="`text-${
              variant.toLowerCase() !== 'light'
                ? variant
                : isDarkSkin
                ? 'light'
                : 'secondary'
            }`"
          >
            <div
              class="mr-1 rounded-lg p-50 d-flex justify-content-center align-items-center"
              style="background-color: #a7c5ff"
            >
              <img
                v-if="svg"
                :src="`${assetsBaseUrl}/${svg}`"
                width="30"
                height="30"
              />
            </div>
            {{ titleType[type] }}
          </h4>
          <b-button-group class="d-none">
            <b-button
              v-b-tooltip.hover.top="'GRAPHIC'"
              :variant="viewChart ? 'primary' : 'outline-primary'"
              size="sm"
              @click="viewChart = true"
            >
              <feather-icon icon="PieChartIcon"></feather-icon>
            </b-button>
            <b-button
              v-b-tooltip.hover.top="'LIST'"
              :variant="!viewChart ? 'primary' : 'outline-primary'"
              size="sm"
              @click="viewChart = false"
            >
              <feather-icon icon="ListIcon"></feather-icon>
            </b-button>
          </b-button-group>
        </div>
      </template>
      <b-card-body class="m-0 pb-0 pt-50 px-0">
        <b-row
          class="py-2 px-0 rounded"
          :class="isDarkSkin ? 'bg-dark' : 'custom-bg'"
          style="min-height: 420px !important"
        >
          <b-col cols="12" sm="6">
            <h5 class="text-uppercase px-1 mb-2 font-weight-bolder pb-1">
              Recommended {{ `(${totalRecommended})` }}
            </h5>
            <vue-apex-charts
              v-if="totalRecommended > 0"
              :key="keyChart"
              type="bar"
              height="320"
              :options="chartOptions"
              :series="series"
            />
            <div
              class="d-flex justify-content-center align-items-center w-100 position-relative"
              style="height: 80%"
              v-else
            >
              <img
                src="@/assets/images/noData/no-data-score-card.svg"
                class="custom-img"
              />
              <span class="mt-3 text-uppercase custom-span"
                >No service has been recommended</span
              >
            </div>
          </b-col>
          <b-col cols="12" sm="6" class="border-xl-left">
            <h5 class="text-uppercase px-1 mb-2 font-weight-bolder pb-1">
              PAIDS {{ `(${totalAccepted})` }}
              <b-badge variant="light-success" class="cursor-pointer" @click="getGlobalDetails" style="font-size: 15px">
                $ {{ totalAmountAccepted | currency }}</b-badge>
            </h5>
            <vue-apex-charts
              v-if="totalAccepted > 0"
              :key="keyChart"
              type="bar"
              height="320"
              :options="chartOptions2"
              :series="series2"
            />
            <div
              class="d-flex justify-content-center align-items-center w-100 position-relative"
              style="height: 85%"
              v-else
            >
              <img
                src="@/assets/images/noData/no-data-score-card.svg"
                class="custom-img"
              />
              <span class="mt-3 text-uppercase custom-span"
                >No service has been accepted</span
              >
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-0">
          <b-col cols="12" class="text-center">
            <b-badge
              class="custom-margin p-50 rounded"
              variant="light-primary"
              v-for="(item, index) in namesModules"
              :key="index"
              style="font-size: 13px"
            >
              {{ `${item.slug} : ${item.name}` }}
              <template v-if="item.submodules">
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="AlertCircleIcon"
                  size="18"
                  :id="`${item.name}-${type}`"
                />
                <b-tooltip :target="`${item.name}-${type}`">
                  <ul class="list-unstyled text-left mb-0 pb-0">
                    <li
                      class="text-left"
                      v-for="(sub, index) in item.submodules"
                      :key="index"
                    >
                      * {{ sub }}
                    </li>
                  </ul>
                </b-tooltip>
              </template>
            </b-badge>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!--MODAL DETAILS OF BAR-->
    <modal-details
      v-if="showModalDetails"
      :params="paramsDetails"
      @closeModal="closeDetails"
    />
  </div>
</template>
  
<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import ModalDetails from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/otherServices/Modals/ModalDetails.vue";

export default {
  components: {
    VueApexCharts,
    ModalDetails,
  },
  props: {
    type: {
      type: Number,
      require: true,
      default: null,
    },
    data: {
      type: Array,
      require: true,
      default: () => [],
    },
    variant: {
      type: String,
      require: false,
      default: "light",
    },
    svg: {
      type: String,
      require: false,
      default: "ListIcon",
    },
    showChart: {
      type: Boolean,
      require: false,
      default: false,
    },
    showNoPaid: {
      type: Boolean,
      require: false,
      default: true,
    },
    month: {
      type: Number,
      require: true,
      default: 0,
    },
    year: {
      type: Number,
      require: true,
      default: 0,
    },
    specialistId: {
      type: Number,
      require: true,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      G_DEPARTMENTS: "DashboardFinancialStore/G_DEPARTMENTS",
    }),
  },
  mounted() {
    this.viewChart = this.showChart;
    this.generateGraphics();
    this.chartOptions.xaxis.labels.style.colors = this.isDarkSkin
      ? "#ffffff"
      : "#2E4053";
    this.chartOptions.yaxis.labels.style.colors = this.isDarkSkin
      ? "#ffffff"
      : "#2E4053";
    this.chartOptions.legend.labels.colors = this.isDarkSkin
      ? "#ffffff"
      : "#2E4053";
    this.chartOptions2.xaxis.labels.style.colors = this.isDarkSkin
      ? "#ffffff"
      : "#2E4053";
    this.chartOptions2.legend.labels.colors = this.isDarkSkin
      ? "#ffffff"
      : "#2E4053";
  },
  data() {
    return {
      isLoading: false,
      viewChart: false,
      keyChart: 0,
      series: [],
      series2: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 280,
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter: (event, chartContext) => {
              const tooltip = chartContext.el.querySelector(
                ".apexcharts-tooltip"
              );
              const tooltipTitle = chartContext.el.querySelector(
                ".apexcharts-tooltip-title"
              );
              const color = this.isDarkSkin ? "#F7F7F7" : "#fffff";
              const backgroundColor = this.isDarkSkin ? "#3A3A3A" : "#ffffff";
              if (tooltip) {
                tooltip.style.backgroundColor = backgroundColor;
                tooltip.style.color = color;
                tooltip.style.border = "0px solid #000000";
              }
              if (tooltipTitle) {
                tooltipTitle.style.backgroundColor = this.isDarkSkin
                  ? "transparent"
                  : "#e4eaec";
                tooltipTitle.style.color = color;
                tooltipTitle.style.borderBottom = "0px solid #000000";
              }
            },
            click: (event, chartContext, config) => {
              const slug =
                this.chartOptions2.xaxis.categories[config.dataPointIndex];
              const { id, name } = this.G_DEPARTMENTS.find(
                (item) => item.initial === slug
              );
              this.paramsDetails = {
                departmentId: id,
                nameDepartment: name,
                type: this.titleType[this.type],
                typeId: this.type,
                year: this.year,
                month: this.month,
                specialistId: this.specialistId,
                img: this.svg,
                statusService: [2,1,5][config.seriesIndex]
              };
              this.showModalDetails = true;
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: 0,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            // columnWidth: '20%',
            endingShape: "rounded",
            borderRadius: 50,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "string",
          categories: [],
          labels: {
            style: {
              colors: null,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: null,
            },
            formatter: (value) => {
              return parseInt(value, 10);
            },
          },
        },
        legend: {
          position: "bottom",
          offsetY: 8,
          labels: {
            style: {
              colors: null,
            },
          },
          fontSize: "14px",
        },
        fill: {
          opacity: 1,
          colors: ["#3F7AFA", "#FFBC3B", "#FD4C50"],
        },
        colors: ["#3F7AFA", "#FFBC3B", "#FD4C50"],
        stroke: {
          show: true,
          curve: "straight",
          lineCap: "butt",
          colors: "#ffffff",
          width: 3,
          dashArray: 0,
        },
      },
      chartOptions2: {
        chart: {
          type: "bar",
          height: 280,
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter: (event, chartContext, config) => {
              const tooltip = chartContext.el.querySelector(
                ".apexcharts-tooltip"
              );
              const tooltipTitle = chartContext.el.querySelector(
                ".apexcharts-tooltip-title"
              );
              const color = this.isDarkSkin ? "#F7F7F7" : "#fffff";
              const backgroundColor = this.isDarkSkin ? "#3A3A3A" : "#ffffff";
              if (tooltip) {
                tooltip.style.backgroundColor = backgroundColor;
                tooltip.style.color = color;
                tooltip.style.border = "0px solid #000000";
              }
              if (tooltipTitle) {
                tooltipTitle.style.backgroundColor = this.isDarkSkin
                  ? "transparent"
                  : "#e4eaec";
                tooltipTitle.style.color = color;
                tooltipTitle.style.borderBottom = "0px solid #000000";
              }
            },
            click: (event, chartContext, config) => {
              const slug =
                this.chartOptions2.xaxis.categories[config.dataPointIndex];
              const { id, name } = this.G_DEPARTMENTS.find(
                (item) => item.initial === slug
              );
              this.paramsDetails = {
                departmentId: id,
                nameDepartment: name,
                type: this.titleType[this.type],
                typeId: this.type,
                year: this.year,
                month: this.month,
                specialistId: this.specialistId,
                img: this.svg,
                statusService: [3,4][config.seriesIndex]
              };
              this.showModalDetails = true;
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: !this.showNoPaid ? "20px" : "50px",
            endingShape: "rounded",
            borderRadius: 100,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
              cursor: 'pointer'
            },
          },
        },
        xaxis: {
          type: "string",
          categories: [],
          labels: {
            style: {
              colors: null,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: null,
            },
            formatter: (value) => {
              return parseInt(value, 10);
            },
          },
        },
        legend: {
          position: "bottom",
          offsetY: 8,
          labels: {
            style: {
              colors: null,
            },
          },
          fontSize: "14px",
        },
        fill: {
          opacity: 1,
          colors: ["#3FC600", !this.showNoPaid ? "#b8b8b800" : "#B2BABB"],
        },
        colors: ["#3FC600", !this.showNoPaid ? "#b8b8b800" : "#B2BABB"],
        stroke: {
          show: true,
          curve: "straight",
          lineCap: "butt",
          colors: "#ffffff",
          width: 3,
          dashArray: 0,
        },
      },
      totalRecommended: 0,
      totalAccepted: 0,
      namesModules: [],
      assetsBaseUrl: `${process.env.BASE_URL}assets/images/icons/`,
      totalAmountAccepted: 0,
      showModalDetails: false,
      paramsDetails: {},
      titleType: {
        1: "CREDIT CARDS",
        2: "APPOINTMENTS",
        3: "OTHER SERVICES",
        4: "POTENTIAL",
      },
    };
  },
  methods: {
    generateGraphics() {
      const dataRecommendeds = this.data.map((item) => {
        return {
          module_name: item.module_name,
          module_slug: item.module_slug,
          recommended: {
            pending: item.recommended[0].pending,
            accepted: item.recommended[0].accepted,
            rejected: item.recommended[0].rejected,
          },
        };
      });
      const dataAccepted = this.data.map((item) => {
        return {
          module_name: item.module_name,
          module_slug: item.module_slug,
          paids: {
            cant: item.paids[0].cant,
            amount: item.paids[0].amount,
          },
          noPaids: item.no_paid_cant,
        };
      });

      this.getNameModules();
      this.setDataRecommendeds(dataRecommendeds);
      this.setDataAccepted(dataAccepted);

      this.keyChart += 1;
    },
    setDataRecommendeds(data) {
      let accepted = [];
      let pending = [];
      let rejected = [];

      for (let index = 0; index < data.length; index++) {
        const {
          accepted: acc,
          pending: pen,
          rejected: rej,
        } = data[index].recommended;
        accepted.push(acc);
        pending.push(pen);
        rejected.push(rej);
      }

      this.series = [
        {
          name: "Accepted   ",
          data: accepted,
        },
        {
          name: "Pending",
          data: pending,
        },
        {
          name: "Rejected  ",
          data: rejected,
        },
      ];
      this.chartOptions.xaxis.categories = data.map((item) => item.module_slug);

      const values = [...accepted, ...pending, ...rejected];
      this.totalRecommended = values.reduce((sum, val) => {
        return sum + val;
      }, 0);
    },
    setDataAccepted(data) {
      let paids = [];
      let noPaids = [];
      let paidAmount = [];

      for (let index = 0; index < data.length; index++) {
        paids.push(data[index].paids.cant);
        if (this.showNoPaid) {
          noPaids.push(data[index].noPaids);
        }
        paidAmount.push(data[index].paids.amount);
      }
      this.series2 = [
        {
          name: "Paid  ",
          data: paids,
        },
        {
          name: this.showNoPaid ? "No Paid" : "",
          data: noPaids,
        },
      ];

      this.chartOptions2.xaxis.categories = data.map(
        (item) => item.module_slug
      );

      const values = paids;
      this.totalAccepted = values.reduce((sum, val) => {
        return sum + val;
      }, 0);

      this.totalAmountAccepted = paidAmount.reduce((sum, val) => {
        return sum + val;
      }, 0);

      // return the total to main component
      this.$emit("totalAmount", this.totalAmountAccepted);
    },
    getNameModules() {
      this.namesModules = this.data.map((item) => {
        return {
          slug: item.module_slug,
          name: item.module_name,
          submodules: item.submodules,
        };
      });
    },
    closeDetails() {
      this.showModalDetails = false;
      this.paramsDetails = {};
    },
    getGlobalDetails() {
      this.paramsDetails = {
        departmentId: null,
        nameDepartment: 'ALL DEPARTMENTS',
        type: this.getTypeServiceName(this.type),
        typeId: this.type,
        year: this.year,
        month: this.month,
        specialistId: this.specialistId,
        img: null,
        statusService: 3,
      };
      this.showModalDetails = true;
    },
    getTypeServiceName(value) {
      const names = {
        1: 'CREDIT CARDS',
        2: 'APPOINTMENTS',
        3: 'OTHER SERVICES',
        4: 'POTENTIAL',
      }

      return names[value];
    }
  },
  watch: {
    isDarkSkin(newVal) {
      this.chartOptions.xaxis.labels.style.colors = newVal
        ? "#ffffff"
        : "#2E4053";
      this.chartOptions.yaxis.labels.style.colors = newVal
        ? "#ffffff"
        : "#2E4053";
      this.chartOptions.legend.labels.colors = newVal ? "#ffffff" : "#2E4053";
      this.chartOptions2.xaxis.labels.style.colors = newVal
        ? "#ffffff"
        : "#2E4053";
      this.chartOptions2.yaxis.labels.style.colors = newVal
        ? "#ffffff"
        : "#2E4053";
      this.chartOptions2.legend.labels.colors = newVal ? "#ffffff" : "#2E4053";
      this.keyChart += 1;
    },
  },
};
</script>
  
<style scoped>
.custom-variant {
  padding: 5px 8px;
  background-color: #d1dfff;
  color: #3f7afa;
}
.custom-margin {
  margin: 5px 8px;
}
.custom-bg {
  background-color: #f9f9f9;
}
.custom-img {
  /* padding: 20px 20px; */
  width: 200px;
  min-width: 100px;
  height: 200px;
  opacity: 0.1;
}
.custom-span {
  position: absolute;
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
  letter-spacing: 1px;
  padding: 0px 20%;
  z-index: 2;
  color: #979595;
}
</style>