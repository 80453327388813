<template>
  <b-card class="p-50 rounded-lg pb-0" style="height: 90% !important">
    <template #header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4
          class="font-weight-bolder text-uppercase mb-0 d-flex justify-content-start align-items-center"
        >
          <div
            class="mr-1 rounded-lg p-50 d-flex justify-content-center align-items-center"
            style="background-color: #a7c5ff"
          >
            <img
              src="@/assets/images/icons/departments.svg"
              width="30"
              height="30"
            />
          </div>
          Departments Productivity
          <b-button variant="warning" class="rounded-circle ml-1 p-0" size="sm">
            <feather-icon
              size="20"
              icon="HelpCircleIcon"
              v-b-tooltip.hover.v-warning="
                'PRODUCTIVITY IS OBTAINED ON THE BASIS OF SERVICES PAID FOR.'
              "
              v-b-tooltip.hover.right
            ></feather-icon>
          </b-button>
        </h4>
        <b-button-group class="ml-50">
          <b-button
            size="sm"
            :variant="showAmount ? 'primary' : 'outline-primary'"
            class="text-uppercase"
            v-b-tooltip.hover.v-primary="'AMOUNT'"
            @click="showAmount = true"
          >
            <feather-icon icon="DollarSignIcon" size="12" />
          </b-button>
          <b-button
            size="sm"
            :variant="!showAmount ? 'primary' : 'outline-primary'"
            class="text-uppercase"
            v-b-tooltip.hover.v-primary="'QUANTITY'"
            @click="showAmount = false"
          >
            <feather-icon icon="CreditCardIcon" size="12" />
          </b-button>
        </b-button-group>
      </div>
    </template>
    <b-card-body class="p-0">
      <b-overlay
        :show="isLoading"
        :variant="isDarkSkin ? 'dark' : 'light'"
        class="rounded-lg"
      >
        <vue-apex-charts
          :key="keyChart"
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></vue-apex-charts>
      </b-overlay>
      <b-row class="mt-1">
        <b-col cols="12" class="text-center d-flex justify-content-around">
          <div
            class="d-flex justify-content-center align-items-center"
            v-for="(item, index) in legendsName"
            :key="index"
          >
            <span
              class="rounded-sm custom-item mr-50"
              :style="`background-color: ${item.color}`"
            ></span>
            <strong class="text-capitalize"
              >{{ item.name }}
              <template v-if="showAmount"> ($ {{ item.total | currency }}) </template>
              <template v-else> ({{ item.total  }}) </template>
            </strong>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12" class="text-center">
          <b-badge
            variant="light-primary"
            class="p-50 m-50 custom-margin"
            v-for="(item, index) in namesModules"
            :key="index"
            style="font-size: 13px"
          >
            {{ `${item.slug} : ${item.name}` }}
            <template v-if="item.submodules">
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="AlertCircleIcon"
                size="18"
                :id="item.name"
              />
              <b-tooltip :target="item.name">
                <ul class="list-unstyled text-left mb-0 pb-0">
                  <li
                    class="text-left"
                    v-for="(sub, index) in item.submodules"
                    :key="index"
                  >
                    * {{ sub }}
                  </li>
                </ul>
              </b-tooltip>
            </template>
          </b-badge>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import DashboardServices from "@/views/specialists/sub-modules/financial-approval/views/dashboard/services/dashboard.services";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    month: {
      type: Number,
      require: true,
      default: 0,
    },
    year: {
      type: Number,
      require: true,
      default: 0,
    },
  },
  async mounted() {
    const color = this.isDarkSkin ? "#ffffff" : "#2E4053";
    this.chartOptions.yaxis.labels.style.colors = color;
    this.chartOptions.xaxis.labels.style.colors = color;
    this.chartOptions.legend.labels.colors = color;
    await this.getDataDepartmentProductivity();
  },
  computed: {
    legendsName() {
      return this.series.map((item, index) => {
        const total = item.data.reduce((sum, val) => {
          sum += val;
          return sum;
        }, 0);
        return {
          name: item.name,
          total: this.showAmount ? total.toFixed(2) : total,
          color: this.chartOptions.fill.colors[index],
        };
      });
    },
  },
  data() {
    return {
      isLoading: false,
      keyChart: 0,
      viewAmount: false,
      series: [], // array of objects example: [{name: 'required', data: [1,2,40,5,3]},{name: 'required 2', data: [10,2,40,5,3]}]
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          events: {
            dataPointMouseEnter: (event, chartContext, config) => {
              const tooltip = chartContext.el.querySelector(
                ".apexcharts-tooltip"
              );
              const tooltipTitle = chartContext.el.querySelector(
                ".apexcharts-tooltip-title"
              );
              const color = this.isDarkSkin ? "#F7F7F7" : "#fffff";
              const backgroundColor = this.isDarkSkin ? "#3A3A3A" : "#ffffff";
              if (tooltip) {
                tooltip.style.backgroundColor = backgroundColor;
                tooltip.style.color = color;
                tooltip.style.border = "0px solid #000000";
              }
              if (tooltipTitle) {
                tooltipTitle.style.backgroundColor = this.isDarkSkin
                  ? "transparent"
                  : "#e4eaec";
                tooltipTitle.style.color = color;
                tooltipTitle.style.borderBottom = "0px solid #000000";
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          colors: ["#fff"],
        },
        title: {
          text: undefined, // title of chart
        },
        xaxis: {
          categories: [], // array of values example: [1,2,3,40,5]
          labels: {
            formatter: function (val) {
              return parseInt(val, 10);
            },
            show: true,
            style: {
              colors: "#ffffff",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return val;
            },
            show: true,
            style: {
              colors: "#ffffff",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
          colors: ["#01E363", "#3F7AFA", "#AA00F1", "#3FC8FF"], //if you wan change colors,
        },
        legend: {
          show: false,
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 40,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
        },
        colors: ["#01E363", "#3F7AFA", "#AA00F1", "#3FC8FF"], //if you wan change colors,
      },
      namesModules: [],
      showAmount: true,
      dataProductivity: [],
    };
  },
  methods: {
    async getDataDepartmentProductivity() {
      try {
        this.isLoading = true;
        const params = {
          month: this.month,
          year: this.year,
        };

        const { data } = await DashboardServices.getDepartmentProductivity(
          params
        );
        this.dataProductivity = data;
        this.generateMyData(this.dataProductivity);

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.showErrorSwal();
        console.log("error in getDataDepartmentProductivity: ", error);
      }
    },
    generateMyData(data) {
      if (data.length === 0) {
        this.series = [];
        this.chartOptions.xaxis.categories = [];
        this.keyChart += 1;
        return;
      }

      // names modules
      this.namesModules = data.map((item) => {
        return {
          slug: item.module_slug,
          name: item.module_name,
          submodules: item.submodules,
        };
      });

      //create the "arrays" with the "key of Object"
      const keys = Object.keys(data[0]).filter(
        (item) => !["module_name", "module_slug", "submodules"].includes(item)
      );
      const arrays = keys.reduce((array, item) => {
        array[item.toUpperCase()] = [];
        return array;
      }, {});

      // set values to each array of service
      data.forEach((item) => {
        keys.forEach((key) => {
          const values = this.showAmount
            ? item[key][0].amount
            : item[key][0].cant;
          arrays[key.toUpperCase()].push(values);
        });
      });

      // set values to chart
      const series = keys.map((type) => {
        return {
          name: type.replace("_", " ").toUpperCase(),
          data: arrays[type.toUpperCase()],
        };
      });
      this.series = series;
      this.chartOptions.xaxis.categories = data.map((item) => item.module_slug);

      this.keyChart += 1;
    },
  },
  watch: {
    isDarkSkin(newVal) {
      let color = "#ffffff";
      if (!newVal) {
        color = "#2E4053";
      }
      this.chartOptions.yaxis.labels.style.colors = color;
      this.chartOptions.xaxis.labels.style.colors = color;
      this.chartOptions.legend.labels.colors = color;
      this.keyChart += 1;
    },
    showAmount() {
      this.isLoading = true;
      this.generateMyData(this.dataProductivity);
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.custom-margin {
  margin: 5px 8px;
}
.custom-item {
  width: 15px;
  height: 15px;
  display: block;
}
.custom-checkbox-chart-dash {
  transform: translateY(-5px);
}
.custom-checkbox-chart-dash >>> .custom-control-label {
  padding-left: 0px !important;
}
.text-w {
  color: #fff !important;
}
.custom-checkbox-chart-dash-dark
  >>> .custom-control-input:not(:checked)
  ~ .custom-control-label:before {
  background-color: #0090e7 !important;
  border: 1px solid #ffffff;
}
.custom-checkbox-chart-dash-light
  >>> .custom-control-input:not(:checked)
  ~ .custom-control-label:before {
  background-color: #0090e7 !important;
  border: 1px solid #ffffff;
}
.custom-checkbox-chart-dash >>> label {
  color: #fff !important;
  font-size: 1.5rem !important;
  font-weight: bold;
  margin: 0 !important;
  padding: 5px;
}
.custom-checkbox-chart-dash-dark
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #00d25b !important;
  border: 1px solid #ffffff;
}
.custom-checkbox-chart-dash-light
  >>> .custom-control-input:checked
  ~ .custom-control-label:before {
  background-color: #00d25b !important;
}
.custom-badge-ckecked {
  background: #00d25b;
  color: #fff;
  font-size: 12px;
  transform: translateY(-5px);
}
.custom-badge-not-ckecked {
  background: #0090e7 !important;
  color: #fff;
  font-size: 12px;
  transform: translateY(-5px);
}
</style>